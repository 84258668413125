// Background color variables
$bg-1: #FFFFFF;
$bg-2: #F1F0EE;
$bg-3: #E4E2DF;
$bg-4: #D7D5D1;
$bg-5: #EEF2FF;

// Define CSS classes with the same names as background color variables
.bg-1 {
  background-color: $bg-1;
  // You can add more styles as needed
}

.bg-2 {
  background-color: $bg-2;
  // Additional styles for bg-2 class
}

.bg-3 {
  background-color: $bg-3;
  // Additional styles for bg-3 class
}

.bg-4 {
  background-color: $bg-4;
  // Additional styles for bg-4 class
}

.bg-5 {
  background-color: $bg-5;
  // Additional styles for bg-5 class
}

.bg-login {
  background: linear-gradient(124deg, #E9EFFD 9.71%, #F2EBFD 94.12%);
}

// flow background
.bg-emerald {
  background-color: #ECFDF5;
}

.bg-yellow {
  background-color: #FEFCE8;
}

.bg-sky {
  background-color: #F0F9FF;
}

.bg-fuchsia {
  background-color: #FDF4FF;
}

// text color
.text-emerald {
  color: #059669;
}

.text-yellow {
  color: #EAB308;
}

.text-sky {
  color: #0EA5E9;
}

.text-fuchsia {
  color: #D946EF;
}

//sidebar
.menu-item span{
  width: 50px;
  height: 40px;
  background-color: $bg-3;
}

.menu-item:hover span, .bg-primary {
  color: white;
  background: var(--Primary-Logo, linear-gradient(124deg, #2563EB 9.71%, #7C3AED 94.12%));
}
.menu-item:hover{
  background: var(--indigo-indigo-200, #C7D2FE);
}
.menu-item-active{
  background: var(--indigo-indigo-200, #C7D2FE);
}
.menu-item-active span{
  color: white;
  background: var(--Primary-Logo, linear-gradient(124deg, #2563EB 9.71%, #7C3AED 94.12%));
}

/* Apply ellipsis to a single line of text */
.ellipsis {
  white-space: nowrap;     /* Prevent text from wrapping to the next line */
  overflow: hidden;        /* Hide any overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
}

/* BUTTON */
/* Basic Button Style */
.btn {
  @apply rounded rounded-md px-4 py-2 mx-1 text-center cursor-pointer inline-block transition duration-300 ease-in-out;
}

.btn-sm{
  @apply rounded rounded-md px-2 py-1 mx-1 text-center cursor-pointer inline-block transition duration-300 ease-in-out;
}
.btn-lg{
  @apply rounded rounded-md px-2 py-3 mx-1 text-center cursor-pointer inline-block transition duration-300 ease-in-out;
}

/* Filled Buttons */
.btn-primary {
  @apply text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800;
}

/* Filled Secondary Button */
.btn-secondary {
  @apply text-white bg-gradient-to-br from-gray-500 to-gray-300 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800;
}

/* Filled Danger Button */
.btn-danger {
  @apply text-white bg-gradient-to-br from-red-600 to-pink-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800;
}

/* Filled Success Button */
.btn-success {
  @apply text-white bg-gradient-to-br from-green-600 to-teal-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800;
}

/* Filled Warning Button */
.btn-warning {
  @apply text-white bg-gradient-to-br from-yellow-600 to-amber-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800;
}

/* Filled Info Button */
.btn-info {
  @apply text-white bg-gradient-to-br from-blue-400 to-cyan-300 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800;
}

/* Filled Dark Button */
.btn-dark {
  @apply text-white bg-gradient-to-br from-gray-800 to-gray-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800;
}

/* Filled Light Button */
.btn-light {
  @apply text-gray-900 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800;
}


.btn-link {
  @apply text-blue-500 hover:underline;
}

/* Outlined Buttons */
.btn-outline-primary {
  @apply border-2 border-blue-500 text-blue-500 hover:bg-blue-100;
}

.btn-outline-secondary {
  @apply border border-gray-500 text-gray-500 hover:bg-gray-100;
}

.btn-outline-success {
  @apply border border-green-500 text-green-500 hover:bg-green-100;
}

.btn-outline-danger {
  @apply border border-red-500 text-red-500 hover:bg-red-100;
}

.btn-outline-warning {
  @apply border border-yellow-500 text-yellow-500 hover:bg-yellow-100;
}

.btn-outline-info {
  @apply border border-teal-500 text-teal-500 hover:bg-teal-100;
}

.btn-outline-light {
  @apply border border-gray-200 text-gray-800 hover:bg-gray-100;
}

.btn-outline-dark {
  @apply border border-gray-800 text-gray-800 hover:bg-gray-200;
}

.btn-outline-link {
  @apply text-blue-500 hover:underline;
}

/* FAB Button */
.fab{
  @apply rounded-full w-10 h-10 flex items-center justify-center;
}

/* BUTTON SWITCH */
.btn-switch{
  @apply float-left rounded-lg p-1 bg-2 rounded-lg;
}
.btn-switch .btn{
  @apply rounded-lg py-2 px-4 bg-2 font-medium text-base;
}
.btn-switch .btn-active{
  @apply rounded-lg py-2 px-4 bg-1 font-medium text-base;
}
/*BUTTON ICON ONLU */
.btn-icon-only{
  @apply flex items-center justify-center w-10 h-10;
}
.btn-icon-disabled {
  @apply cursor-not-allowed;
}
/* TABLE */
.table{
  @apply min-w-full divide-y divide-gray-200;
  border-radius: 8px 8px 0px 0px;
}
.table th{
  @apply px-3 py-3 bg-gray-50 text-left;
  background: var(--violet-violet-50, #F5F3FF);

}
.table tbody{
  @apply bg-white divide-y divide-gray-200;
}
.table td{
  @apply py-4 px-3 whitespace-nowrap;
}

/* TABLE PACKAGES */
.table-packages{
  @apply min-w-full divide-y divide-gray-200;
  border-radius: 8px 8px 0px 0px;
}
.table-packages th{
  @apply px-3 py-3 bg-gray-50 text-left;
  background: var(--violet-violet-50, #F5F3FF);

}
.table-packages tbody{
  @apply bg-white divide-y divide-gray-200;
}
.table-packages td{
  @apply py-4 px-3 whitespace-normal;
}

/* PAGE TOOLS */
.page-tools{
  @apply w-full flex flex-row items-center justify-end mb-5;
}

/* FORMS */
.search{
  @apply relative;
}
.search .input{
  @apply pl-10 pr-4 py-2 w-64 border border-gray-300 rounded-lg outline-none;
}
.search .icon {
  @apply absolute inset-y-0 left-0 flex items-center pl-3;
}
.search .icon i{
  @apply text-gray-400;
}

.checkbox{
  @apply h-5 w-5 rounded-md text-indigo-600;
}

.input-pre{
  @apply flex items-center;
}
.input-pre span{
  @apply text-gray-400;
}
.input-pre .input{
  @apply ml-2;
}

.form-control{
  @apply mb-4;
}
.form-label{
  @apply block text-gray-700 text-sm font-bold mb-3;
}
.form-input{
  @apply w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500;
}
.form-input-with-button{
  @apply w-full px-3 py-2 border border-gray-300 rounded-none rounded-l-md focus:outline-none focus:border-blue-500;
}
.form-input-button{
  @apply inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md
}
.form-disabled {
  @apply bg-gray-100 border border-gray-300 text-gray-900 cursor-not-allowed
}
.form-textarea{
  @apply w-full px-3 py-2 border border-gray-300 rounded-md resize-none h-32 focus:outline-none focus:border-blue-500;
}

/* BADGES */
.badge{
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
}
.badge-success{
  @apply bg-green-100 text-green-800;
}
.badge-info{
  @apply bg-sky-100 text-sky-800;
}
.badge-danger{
  @apply bg-red-100 text-red-800;
}

/* MODAL */
.modal{
  @apply overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex;
}
.modal-lg{
  @apply max-w-2xl max-h-full;
}
.modal-sm{
  @apply max-w-lg max-h-full;
}
.modal-wrapper{
  @apply relative w-full max-w-2xl max-h-full;
}
.modal-content{
  @apply border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none;
}
.modal-header{
  @apply flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t;
}
.modal-title{
  @apply font-semibold;
}
.modal-body{
  @apply relative p-6 flex-auto;
}
.modal-backdrop{
  @apply opacity-25 fixed inset-0 z-40 bg-black;
}
.modal-close{
  @apply text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white;
}
.modal-footer{
  @apply flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b;
}

/* IMAGES */
.thumbnail{
  @apply rounded-md w-20;
}
.avatar{
  @apply w-10 h-10 rounded-full;
}


/* CARD */
.card{
  @apply bg-1 shadow-md rounded-lg col-span-1 flex flex-col h-full;
}
.card-header{
  @apply px-4 py-2;
}
.card-title{
  @apply h4;
}
.card-body{
  @apply p-5;
}
.card-footer{
  @apply flex items-center justify-center p-4;
}

/* TABS */
.tabs{
  @apply flex items-center border-b border-gray-500;
}
.tab-item{
  @apply py-0;
}
.tab-btn{
  @apply py-2 px-4 text-base font-medium;
}
.tab-active{
  @apply border-b border-b-2 border-purple-600;
}

/* DROPDOWN */
.dropdown{
  @apply  relative;
}
.dropdown-wrapper{
  @apply absolute z-50 w-56 px-2 py-0 bg-white divide-y divide-gray-300 rounded-lg shadow dark:bg-gray-700;
}
.dropdown-item{
  @apply flex items-center justify-start hover:bg-gray-100 p-2 rounded-md cursor-pointer;
}
.dropdown-item i{
  @apply w-10;
}
.dropdown-item a{
  @apply w-full block;
}

/* TYPOGRAPH */

/* Heading Text Classes */
.h1 {
  @apply text-4xl font-bold my-6; /* Adjust the values as needed */
}

.h2 {
  @apply text-3xl font-semibold my-5; /* Adjust the values as needed */
}

.h3 {
  @apply text-2xl font-semibold my-4; /* Adjust the values as needed */
}

.h4 {
  @apply text-xl font-semibold mt-3 my-2; /* Adjust the values as needed */
}

.h5 {
  @apply text-lg font-semibold my-2; /* Adjust the values as needed */
}

/*PAGE HEADER */
.page-header{
  @apply w-full flex items-center text-base font-medium border-b border-gray-400 p-3 bg-2;
}

/* LIST */
.list-item{
  @apply flex justify-between items-center py-3 border-b border-gray-300;
}